import React, { Component } from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import adyen from 'adyen-cse-web'
import { createDeposit } from '../../actions/deposit'
import env from '../../env'
import Axios from 'axios'
import PaymentTypes from './PaymentTypes'

const CREDIT_CARD_FEE = 5.0 / 100
const CREDIT_CARD_TAX = 0.0

class CreditCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        CardNumber: '',
        Name: '',
        Month: '',
        Year: '',
        CVC: '',
        Installments: 0,
        isValid: false
      },
      amount: {
        deposit: 0,
        total: 0,
        tax: 0
      },
      loading: false,
      error: ''
    }
  }

  componentDidMount() {
    let { amount: deposit, redir } = parse(this.props.location.search)
    deposit = Number(deposit)
    if (!deposit) {
      window.open('#/dashboard/deposit/amount', '_self')
      return
    }
    if (!redir) {
      redir = '#/dashboard/transactions'
    }
    let tax = deposit * CREDIT_CARD_FEE + CREDIT_CARD_TAX
    let total = deposit + tax
    this.setState({ amount: { deposit, tax, total }, redir })
  }

  getAcceptedYears(count) {
    const current = (new Date()).getFullYear()
    return (new Array(count)).fill(0)
      .map((v, i) => String(current + i))
  }

  getInstallments(amount, ...count) {
    return count.map(v => (
      {
        count: v,
        value: amount / v
      }
    ))
  }

  change(field, format = String) {
    return event => {
      let { form } = this.state
      form[field] = format(event.target.value)
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  cardNumber(input) {
    let sanitized = input.replace(/\D/g, '')
    let numbers = []
    for (let i = 0; i < 16; i += 4) {
      let s = sanitized.trim().substring(i, i + 4)
      if (s) {
        numbers.push(s)
      }
    }
    return numbers.join(' ')
  }

  cvc(input) {
    let sanitized = input.replace(/\D/g, '')
    return sanitized.substring(0, 4)
  }

  async confirm() {
    try {
      this.setState({ error: null, loading: true })

      const { createDeposit, user } = this.props
      const { amount, redir, form: { CardNumber, Name, Month, Year, CVC } } = this.state

      const deposit = await createDeposit({ amount: amount.deposit })

      const encrypted = this.encrypt(CardNumber, Name, Month, Year, CVC)

      let { data: { gateway } } = await Axios.post(env.creditCard.action, {
        application: 'digital_wallet',
        product: 'deposit',
        country_code: 'br',
        id_user: deposit.SenderID,
        email: user.Email,
        amount: {
          value: amount.total.toFixed(2).replace('.', ''),
          currency: 'BRL'
        },
        my_data: deposit.ID,
        ...encrypted
      }, { headers: { Authorization: `Basic ${env.creditCard.token}` } })

      const { reason, status, message } = gateway
      if (status !== 'Success') {
        throw `${message}: ${reason}`
      }

      this.setState({ loading: false })
      let params = `transaction=${deposit.ID}&redir=${redir}`
      window.open(`#/dashboard/deposit/wait?${params}`, '_self')

    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  encrypt(number, holderName, expiryMonth, expiryYear, cvc) {
    let cse = adyen.createEncryption(env.creditCard.publicKey, {
      enableValidations: false
    })

    let data = {
      number, holderName, expiryMonth, expiryYear, cvc,
      generationtime: new Date().toJSON()
    }

    let validated = cse.validate(data)
    for (const i in validated) {
      if (validated.hasOwnProperty(i) && i != 'valid') {
        if (!validated[i]) {
          throw `${i} is invalid`
        }
      }
    }

    return { 'card-encrypt': cse.encrypt(data) }
  }

  render() {
    const { location } = this.props
    const { amount, form, loading, error } = this.state

    return (
      <div className="CreditCard columns is-centered is-flex has-scrollbar">
        <div className="column is-two-thirds-tablet is-half-widescreen is-one-third-fullhd">
          <p className="title is-5 has-text-centered">Enviar fundos para sua Carteira Digital</p>
          <div className="notification is-danger" hidden={!error}>
            {String(error)}
          </div>
          <PaymentTypes pathname={location.pathname} search={location.search}>
            <div className="columns">
              <div className="column">
                <p>Transferência segura de dinheiro usando sua conta bancária</p>
              </div>
              <div className="column has-text-right">
                <p>Você está enviando</p>
                <p className="is-size-5 has-text-success">R$ {amount.deposit.toFixed(2)}</p>
              </div>
            </div>
            <form className="columns is-multiline is-mobile" ref="form">
              <div className="column is-full-mobile is-full-tablet is-half-desktop">
                <div className="field">
                  <label className="label">Número do cartão</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="0000 0000 0000 0000" value={form.CardNumber} pattern="^[\d\s]{18,19}$" onChange={this.change('CardNumber', this.cardNumber)} required />
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-full-tablet is-half-desktop">
                <div className="field">
                  <label className="label">Nome do titular do cartão</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="Digite o nome do titular" value={form.Name} onChange={this.change('Name')} required />
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half-tablet">
                <div className="field">
                  <label className="label">Expira em</label>
                  <div className="columns is-mobile is-variable is-1">
                    <div className="column">
                      <div className="select is-fullwidth" value={form.Month} onChange={this.change('Month')} required>
                        <select>
                          {
                            (new Array(12)).fill(null)
                              .map((v, i) => i < 9 ? `0${i + 1}` : String(i + 1))
                              .map((v, i) => (
                                <option key={i} val={v}>{v}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="column">
                      <div className="select is-fullwidth">
                        <select value={form.Year} onChange={this.change('Year')} required>
                          {this.getAcceptedYears(20).map((v, i) => (
                            <option key={i} value={v}>{v}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half-tablet">
                <div className="field">
                  <label className="label">CVC</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="000" pattern="^[\d]{3,4}$" value={form.CVC} onChange={this.change('CVC', this.cvc)} required />
                  </div>
                </div>
              </div>
            </form>
            <div className="columns is-size-7 is-marginless">
              <div className="column">
                Taxa adicional de R$ {amount.tax.toFixed(2)}
              </div>
              <div className="column has-text-right-tablet">
                Valor total a ser pago R$ {amount.total.toFixed(2)}
              </div>
            </div>
          </PaymentTypes>
          <p className="has-text-centered">
            <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.confirm.bind(this)} disabled={!form.isValid} type="button">Confirmar</button>
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, { createDeposit })(CreditCard)
