const environments = {
  production: {
    api: window.location.origin,
    sociotasking: 'https://app.sociotasking.com',
    paypal: {
      action: 'https://www.paypal.com/cgi-bin/webscr',
      notify: 'https://api-payment.builderall.com/notification/paypal',
      business: 'contact@pagamentoparalelo.com'
    },
    boleto: {
      action: 'https://api-payment.builderall.com/pay/boleto',
      token: 'QWNjZXNzQnVpbGRlcmFsbEdhdGV3YXlQcm86RS1idXNpbm5lcytCdWlsZGVyYWxsMjAxOSE='
    },
    creditCard: {
      action: 'https://api-payment.builderall.com/pay/single',
      token: 'QWNjZXNzQnVpbGRlcmFsbEdhdGV3YXlQcm86RS1idXNpbm5lcytCdWlsZGVyYWxsMjAxOSE=',
      publicKey: `
      10001|D7004B232ED20CA4A787BD26612DC8B174F89D702B64F149E9FAAA7F9C6F0FB42E95
      4434CCC76C18848CE7A3F37E2F3B4C9DD620F5749ED5F7BB3C47A944EB793FE4EFBF6B24F9
      701635709DCCB692DCC8234E585B523193ED325C0F342557D9938B8E63E7086DCADD23A2B4
      EF9C7766DA490F5C8B3811321F4CBD89ABCDCC1657F49F9E5E20B6691325E58024FF9357CE
      092114EDA05887631D829BADE4DA5F9D6F599E4F3A86B5BDEB97567820842B22A8143C2183
      39E99E65261E09CB6BA92E6A5FC8638478F2A8E65824DD93F6E14AD5DC0A8E0CBC6CC3B167
      7A1126E976F07E8EF0B1CB6876BC0F8162170D039C12EF660F443A34EF269CF7E9A2419173
      `.replace(/[\n,\t,\s]+/g, '')
    }
  },
  test: {
    api: window.location.origin,
    sociotasking: 'https://sociotasking.builderall.io',
    paypal: {
      action: 'https://www.sandbox.paypal.com/cgi-bin/webscr',
      notify: 'https://api-payment.builderall.io/notification/paypal',
      business: 'anderson@hotmail.com'
    },
    boleto: {
      action: 'https://api-payment.builderall.io/pay/boleto',
      token: 'QWNjZXNzQnVpbGRlcmFsbEdhdGV3YXlQcm86RS1idXNpbm5lcytCdWlsZGVyYWxsMjAxOSE='
    },
    creditCard: {
      action: 'https://api-payment.builderall.io/pay/single',
      token: 'QWNjZXNzQnVpbGRlcmFsbEdhdGV3YXlQcm86RS1idXNpbm5lcytCdWlsZGVyYWxsMjAxOSE=',
      publicKey: `
      10001|AEC7A73E57936641AF8692A892DD05EA82F3DAC2D648A9589FE8788B2F67C855163A
      39D0810741D836F2DA7E62772980B9A9A2D83F5DA0453A408F1AF5438FD126D07B92659E87
      11D498D4136ED0056B890B0F98A3C4C6ABF5C97305208EA79612A59C3D59B6D637487AD63F
      D96951BA05D2E3E329823BE2774A16468ED12BCFE9156841D475D8861065E8F3ED91DB5145
      0F21136CF4213635B77D9E11C90DA95443376A8E8742053B097E13B3A10FBCE8DC5ACFDCD7
      180AB18C812B3308E8BF87F72FB3FDBE20AE6F7732ABFFED2D5DCDED499BA11ABA774C2B58
      7AD240D0E860F5F47BF081CA468559B2B7835B26A85364F1E27E1D9C7A0CCF37E91AF481AD
      `.replace(/[\n,\t,\s]+/g, '')
    }
  },
  development: {
    api: '//localhost:8080',
    sociotasking: 'https://sociotasking.builderall.io',
    paypal: {
      action: 'https://www.sandbox.paypal.com/cgi-bin/webscr',
      notify: 'https://api-payment.builderall.io/notification/paypal',
      business: 'anderson@hotmail.com'
    },
    boleto: {
      action: 'https://api-payment.builderall.io/pay/boleto',
      token: 'QWNjZXNzQnVpbGRlcmFsbEdhdGV3YXlQcm86RS1idXNpbm5lcytCdWlsZGVyYWxsMjAxOSE='
    },
    creditCard: {
      action: 'https://api-payment.builderall.io/pay/single',
      token: 'QWNjZXNzQnVpbGRlcmFsbEdhdGV3YXlQcm86RS1idXNpbm5lcytCdWlsZGVyYWxsMjAxOSE=',
      publicKey: `
      10001|AEC7A73E57936641AF8692A892DD05EA82F3DAC2D648A9589FE8788B2F67C855163A
      39D0810741D836F2DA7E62772980B9A9A2D83F5DA0453A408F1AF5438FD126D07B92659E87
      11D498D4136ED0056B890B0F98A3C4C6ABF5C97305208EA79612A59C3D59B6D637487AD63F
      D96951BA05D2E3E329823BE2774A16468ED12BCFE9156841D475D8861065E8F3ED91DB5145
      0F21136CF4213635B77D9E11C90DA95443376A8E8742053B097E13B3A10FBCE8DC5ACFDCD7
      180AB18C812B3308E8BF87F72FB3FDBE20AE6F7732ABFFED2D5DCDED499BA11ABA774C2B58
      7AD240D0E860F5F47BF081CA468559B2B7835B26A85364F1E27E1D9C7A0CCF37E91AF481AD
      `.replace(/[\n,\t,\s]+/g, '')
    }
  }
}

const getEnv = () => {
  const { host } = window.location
  return host.endsWith('.io') ? 'test' : (host.endsWith('.com') || host.endsWith('.net')) ? 'production' : 'development'
}

const env = getEnv()
console.info('application env', env)

export default { env, ...environments[env] }