import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Amount from './Amount'
import Bank from './Bank'

class Withdrawal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="Deposit is-flex columns is-vcentered is-centered">
        <Switch>
          <Route path="/dashboard/withdrawal/amount" component={Amount} />
          <Route path="/dashboard/withdrawal/bank" component={Bank} />
          <Route path="*">
            <Redirect to="/dashboard/withdrawal/amount" />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default Withdrawal
