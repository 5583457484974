import React, { Component } from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { createDeposit } from '../../actions/deposit'
import env from '../../env'
import Axios from 'axios'
import PaymentTypes from './PaymentTypes'

const BOLETO_FEE = 0.0 / 100
const BOLETO_TAX = 3.0

class Boleto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        name: '',
        cpf: ''
      },
      boleto: {
        bar_code: '',
        boleto_url: '',
        due_date: '',
        expiration_date: '',
      },
      deposit: {
        ID: null
      },
      amount: {
        deposit: 0,
        total: 0,
        tax: 0
      },
      loading: false,
      error: ''
    }
  }

  componentDidMount() {
    let { amount: deposit, redir } = parse(this.props.location.search)
    deposit = Number(deposit)
    if (!deposit) {
      window.open('#/dashboard/deposit/amount', '_self')
      return
    }
    if (!redir) {
      redir = '#/dashboard/transactions'
    }
    let tax = deposit * BOLETO_FEE + BOLETO_TAX
    let total = deposit + tax
    this.setState({ amount: { deposit, tax, total }, redir })
  }

  change(field, type = String) {
    return event => {
      let { form } = this.state
      form[field] = type(event.target.value)
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  cpf(input) {
    let sanitized = input.replace(/\D/g, '')

    let formated = sanitized.substring(0, 3)
    if (sanitized.length > 3) formated += `.${sanitized.substring(3, 6)}`
    if (sanitized.length > 6) formated += `.${sanitized.substring(6, 9)}`
    if (sanitized.length > 9) formated += `-${sanitized.substring(9, 11)}`

    return formated
  }

  async confirm() {
    try {
      this.setState({ error: null, loading: true })

      const { createDeposit } = this.props
      const { amount, form, redir } = this.state

      const deposit = await createDeposit({ amount: amount.deposit })

      let { data: { gateway } } = await Axios.post(env.boleto.action, {
        application: 'digital_wallet',
        product: 'deposit',
        country_code: 'br',
        id_user: deposit.SenderID,
        amount: {
          value: amount.total.toFixed(2).replace('.', ''),
          currency: 'BRL'
        },
        name: form.name,
        cpf_or_cnpj: form.cpf,
        my_data: deposit.ID,
      }, { headers: { Authorization: `Basic ${env.boleto.token}` } })

      const { boleto, reason, status, message } = gateway

      if (status !== 'Success') {
        throw `${message}: ${reason}`
      }

      this.setState({ loading: false, deposit, boleto })
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  redir() {
    this.setState({ loading: false })
    const { deposit, boleto, redir } = this.state


    const url = decodeURIComponent(redir)
      .replace('{transaction}', deposit.ID)
      .replace('{type}', 'boleto')
      .replace('{data}', btoa(JSON.stringify(boleto)))

    window.open(url, '_self')
  }

  render() {
    const { location } = this.props
    const { amount, form, boleto, loading, error } = this.state

    return (
      <div className="Boleto columns is-centered is-flex has-scrollbar">
        <div className="column is-half-tablet is-two-fifths-widescreen is-one-third-fullhd">
          <p className="title is-5 has-text-centered">Enviar fundos para sua Carteira Digital</p>
          <div className="notification is-danger" hidden={!error}>
            {String(error)}
          </div>
          {/* <div className="message is-warning has-shadow">
            <div className="message-body">O depósito por boleto estará disponível em sua conta dentro de <b>3 dias úteis</b> após o pagamento. Pode ser pago em qualquer agência bancária, através de seu Internet Banking, ou em uma casa lotérica.</div>
          </div> */}
          <div hidden={boleto.bar_code}>
            <PaymentTypes pathname={location.pathname} search={location.search}>
              <div className="columns is-marginless">
                <div className="column">
                  <p>O depósito por boleto estará disponível em sua conta dentro de <b>3 dias úteis</b> após o pagamento</p>
                </div>
                <div className="column is-one-third">
                  <div className="columns is-marginless is-gapless is-mobile is-multiline is-vcentered">
                    <div className="column is-full-tablet">
                      <div className="has-text-right-tablet">Você está enviando</div>
                    </div>
                    <div className="column is-full-tablet">
                      <div className="is-size-5 has-text-success has-text-right">R$ {amount.deposit.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <form className="columns is-marginless" ref="form" noValidate>
                <div className="column">
                  <div className="field">
                    <label className="label">Nome</label>
                    <div className="control">
                      <input className="input" type="text" placeholder="Digite o seu nome" value={form.name} onChange={this.change('name')} required />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">CPF</label>
                    <div className="control">
                      <input className="input" type="text" placeholder="Digite o seu CPF" minLength="14" value={form.cpf} onChange={this.change('cpf', this.cpf)} required />
                    </div>
                  </div>
                </div>
              </form>
              <div className="columns is-size-7 is-marginless">
                <div className="column">
                  Taxa adicional de R$ {amount.tax.toFixed(2)}
                </div>
                <div className="column has-text-right-tablet">
                  Valor total a ser pago R$ {amount.total.toFixed(2)}
                </div>
              </div>
            </PaymentTypes>
            <p className="has-text-centered">
              <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.confirm.bind(this)} disabled={!form.isValid} type="button">Confirmar</button>
            </p>
          </div>
          <div hidden={!boleto.bar_code}>
            <div className="box">
              <div className="columns is-multiline is-mobile">
                <div className="column is-full has-text-centered">
                  <label className="label">Código de barras</label>
                  <code>{boleto.bar_code}</code>
                </div>
                <div className="column">
                  <label className="label">Expira em</label>
                  <div>{boleto.expiration_date}</div>
                </div>
                <div className="column has-text-right">
                  <label className="label">Valor a ser pago</label>
                  <div>R$ {amount.total.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              <a className="button is-medium is-primary" href={boleto.boleto_url}>Fazer download</a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button className="button is-medium is-primary" type="button" onClick={this.redir.bind(this)}>Voltar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { createDeposit })(Boleto)