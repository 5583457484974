import Axios from 'axios'

export const SET_WITHDRAWAL_OPTION_LAST = 'SET_WITHDRAWAL_OPTION_LAST'

export const lastWithdrawalOption = () => {
  return async dispatch => {
    let { data: last } = await Axios.get('/withdrawals/options')
    dispatch(setWithdrawalOptionLast(last))
    return last
  }
}

export const createWithdrawalOption = o => {
  return async dispatch => {
    let { data: last } = await Axios.post('/withdrawals/options', { ...o, ID: null })
    dispatch(setWithdrawalOptionLast(last))
    return last
  }
}

export const setWithdrawalOptionLast = last => {
  return dispatch => {
    dispatch({ type: SET_WITHDRAWAL_OPTION_LAST, list: last })
  }
}
