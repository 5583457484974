import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './Login'
import logo from '../../assets/images/logo@2x.webp'
import Register from './Register'

class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="Auth section">
        <div className="has-text-centered">
          <img src={logo} alt="Carteira Digital" />
          <p className="title is-5">Carteira Digital<br /><br /></p>
        </div>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default Auth
