import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MD5 } from 'crypto-js'
import './Account.scss'

class Account extends Component {
  gravatar(email) {
    const hash = MD5(email).toString()
    const url = `https://www.gravatar.com/avatar/${hash}?d=mp&s=256`
    return url
  }

  render() {
    const { user } = this.props

    return (
      <div className="Account">
        <div className="columns is-flex is-centered">
          <div className="column is-one-quarter">
            <figure className="image is-128x128">
              <img className="is-rounded has-shadow" src={this.gravatar(user.Email)} alt={user.Name} />
            </figure>
            <div className="box has-text-centered">
              <div className="title is-5">{user.Name}</div>
              <div className="subtitle">{user.Email}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(Account)