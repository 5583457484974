import Axios from 'axios'

export const SET_TRANSACTION_SUMMARY = 'SET_TRANSACTION_SUMMARY'
export const SET_TRANSACTION_LIST = 'SET_TRANSACTION_LIST'
export const SET_TRANSACTION_BALANCE = 'SET_TRANSACTION_BALANCE'

export const setTransactionSummary = summary => {
  return { type: SET_TRANSACTION_SUMMARY, summary }
}

export const setTransactionBalance = (balance, availableForWithdrawal) => {
  return { type: SET_TRANSACTION_BALANCE, balance, availableForWithdrawal }
}

export const setTransactionList = list => {
  return { type: SET_TRANSACTION_LIST, list }
}

export const findTransactionsSummary = () => {
  return async dispatch => {
    let { data: summary } = await Axios.get('/transactions/summary')
    dispatch(setTransactionSummary(summary))
  }
}

export const findTransactionsList = limit => {
  return async dispatch => {
    let { data: list } = await Axios.get(`/transactions?limit=${limit}`)
    dispatch(setTransactionList(list))
  }
}

export const findTransactionsBalance = () => {
  return async dispatch => {
    let { data: { balance, availableForWithdrawal } } = await Axios.get('/transactions/balance')
    dispatch(setTransactionBalance(balance, availableForWithdrawal))
    return balance
  }
}
