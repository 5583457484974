import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { createUser } from '../../actions/user'

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        Name: '',
        Email: '',
        Password: '',
        RepeatPassword: '',
        isValid: false
      },
      loading: false,
      error: ''
    }
  }

  change(field) {
    return event => {
      let { form } = this.state
      form[field] = event.target.value
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  async register() {
    try {
      this.setState({ error: null, loading: true })
      const { form } = this.state
      await this.props.createUser(form)
      this.setState({ loading: false })
      window.open('#/dashboard', '_self')
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { form, loading, error } = this.state
    
    return (
      <div className="Register">
        <div className="columns is-centered">
          <div className="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
            <div className="notification is-danger" hidden={!error}>
              {String(error)}
            </div>
            <form className="box" ref="form">
              <div className="field">
                <label className="label">Nome</label>
                <div className="control">
                  <input className="input" type="text" placeholder="Digite seu nome" value={form.Name} onChange={this.change('Name')} required />
                </div>
              </div>
              <div className="field">
                <label className="label">E-mail</label>
                <div className="control">
                  <input className="input" type="email" placeholder="Digite seu e-mail" autoComplete="username" value={form.Email} onChange={this.change('Email')} required />
                </div>
              </div>
              <div className="field">
                <label className="label">Senha</label>
                <div className="control">
                  <input className="input" type="password" placeholder="Digite sua senha" minLength="6" autoComplete="new-password" value={form.Password} onChange={this.change('Password')} required />
                </div>
              </div>
              <div className="field">
                <label className="label">Confirmar senha</label>
                <div className="control">
                  <input className="input" type="password" placeholder="Digite novamente sua senha" pattern={form.Password} autoComplete="new-password" value={form.RepeatPassword} onChange={this.change('RepeatPassword')} required />
                </div>
              </div>
              <br />
              <div className="columns">
                <div className="column">
                  <Link className="button is-fullwidth" to="/login">Já tenho conta</Link>
                </div>
                <div className="column">
                  <button className={`button is-fullwidth is-primary ${loading && 'is-loading'}`} type="button" onClick={this.register.bind(this)} disabled={!form.isValid}>Criar conta</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, { createUser })(Register)
