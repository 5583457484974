import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MD5 } from 'crypto-js'
import moment from 'moment'
import { findTransactionsList, findTransactionsBalance } from '../../actions/transaction'
import Axios from 'axios'
import './Transfer.sass'

class Transfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        email: '',
        amount: '',
        code: '',
        isValid: false
      },
      auth: { PublicKey: '' },
      user: {},
      error: '',
      loading: false
    }
  }

  async componentDidMount() {
    await this.props.findTransactionsBalance()
  }

  change(field, type = String) {
    return event => {
      let { form } = this.state
      form[field] = type(event.target.value)
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  gravatar(email) {
    const hash = MD5(email).toString()
    const url = `https://www.gravatar.com/avatar/${hash}?d=mp&s=96`
    return url
  }

  async findUser() {
    try {
      this.setState({ loading: true, error: null })
      const { form: { email } } = this.state
      let { data: user } = await Axios.get(`/users?email=${email}`)
      this.setState({ loading: false, user })
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  async verify() {
    try {
      this.setState({ loading: true, error: null })
      const { form, user } = this.state
      let { data: { PublicKey } } = await Axios.post('/transactions/verify', {
        ReceiverID: user.ID,
        Amount: Number(form.amount)
      })
      this.setState({ loading: false, auth: { PublicKey } })
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  async transfer() {
    try {
      this.setState({ loading: true, error: null })
      const { form, user, auth } = this.state
      await Axios.post('/transactions', {
        ReceiverID: user.ID,
        Amount: Number(form.amount),
        PublicKey: auth.PublicKey,
        PrivateKey: form.code
      })
      window.open('#/dashboard/transactions', '_self')
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  amount(input) {
    let sanitized = input.replace(/[\D]+/, '')
    let amount = Number(sanitized.replace(/[\D]+/, '')) / 100
    return amount.toFixed(2)
  }

  render() {
    const { balance } = this.props
    const { form, loading, user, auth, error } = this.state

    return (
      <div className="Transfer columns is-centered is-vcentered is-flex">
        <div className="column columns is-centered has-scrollbar">
          <div className="column is-three-quarters-tablet is-half-desktop is-two-fifths-widescreen is-one-third-fullhd">
            <p className="title is-5 has-text-centered">Transferir fundos da sua Carteira Digital</p>
            <div className="notification is-danger" hidden={!error}>
              {String(error)}
            </div>
            <br />
            <div className="box" hidden={user.ID}>
              <form className="columns is-vcentered is-multiline" ref="form" noValidate>
                <div className="column is-one-third is-size-5">Destinatário</div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input className="input is-large" type="email" placeholder="Digite o e-mail" value={form.email} onChange={this.change('email')} required />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div hidden={!user.ID}>
              <div className="box is-marginless">
                <div className="columns is-mobile is-multiline is-centered">
                  <div className="column is-half-mobile is-one-fifth-tablet">
                    <figure className="image">
                      <img className="is-rounded" src={this.gravatar(user.Email)} alt="Anderson Marin" />
                    </figure>
                  </div>
                  <div className="column is-full-mobile has-text-centered-mobile user-info">
                    <div className="columns" style={{ marginBottom: 0 }}>
                      <div className="column">
                        <p className="is-size-4">{user.Name}</p>
                        <p>{user.Email}</p>
                      </div>
                      <div className="column is-one-quarter has-text-right is-size-5" hidden={!auth.PublicKey}>
                        R$ {form.amount}
                      </div>
                    </div>
                    <p className="has-text-right-tablet is-size-7">Cadastrado {moment(user.CreatedAt).fromNow()}</p>
                  </div>
                </div>
              </div>
              <span className="icon has-text-primary down">
                <i className="fas fa-chevron-down"></i>
              </span>
              <form className="box is-marginless" ref="form" noValidate hidden={auth.PublicKey}>
                <div className="columns is-vcentered">
                  <div className="column is-size-5">Digite o valor</div>
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <input className={`input has-text-right is-large ${Number(balance) < Number(form.amount) && 'is-danger'}`} type="text" pattern="^((?!^0.00$).)*$" placeholder="0.00" value={form.amount} onChange={this.change('amount', this.amount)} required={user.ID} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <table>
                    <tbody>
                      <tr>
                        <td>Saldo atual</td>
                        <td className="has-text-right">R$ {Number(balance).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Saldo restante</td>
                        <td className="has-text-right">R$ {(Number(balance) - Number(form.amount)).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Valor transferido</td>
                        <td className="has-text-right">R$ {Number(form.amount).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
              <form className="box columns is-vcentered is-multiline is-marginless" ref="form" noValidate hidden={!auth.PublicKey}>
                <div className="column">
                  Enviamos um código para o seu email, digite ele na caixa ao lado
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input className="input has-text-centered is-large" type="text" placeholder="Digite aqui" value={form.code} onChange={this.change('code')} required={auth.PublicKey} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="has-text-centered">
              <br />
              <a className={`button is-primary is-large ${loading && 'is-loading'}`} onClick={this.findUser.bind(this)} disabled={!form.isValid} hidden={user.ID}>Buscar</a>
              <a className={`button is-primary is-large ${loading && 'is-loading'}`} onClick={this.verify.bind(this)} disabled={!form.isValid || Number(balance) < Number(form.amount)} hidden={auth.PublicKey || !user.ID}>Transferir</a>
              <a className={`button is-primary is-large ${loading && 'is-loading'}`} onClick={this.transfer.bind(this)} disabled={!form.isValid} hidden={!auth.PublicKey}>Confirmar</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  list: state.transaction.list,
  user: state.user,
  balance: state.transaction.balance,
})

export default connect(mapStateToProps, { findTransactionsList, findTransactionsBalance })(Transfer)
