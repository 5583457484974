import React, { Component } from 'react'
import { parse } from 'query-string'
import loader from '../../assets/images/grid.svg'
import Axios from 'axios'

class Wait extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deposit: {},
      cron: null
    }
  }

  componentDidMount() {
    let { transaction, redir, type } = parse(this.props.location.search)
    if (!redir) {
      redir = '#/dashboard/transactions'
    }
    if (!type) {
      type = 'paypal'
    }
    this.find(transaction, redir)
  }

  async find(id, redir) {
    let { data: deposit } = await Axios.get(`/transactions/find/${id}`)
    this.setState({ deposit })

    if (deposit.Status === 'completed') {
      const { type } = this.state
      this.redir(deposit, type, redir)
    } else {
      let cron = setTimeout(() => this.find(id, redir), 2000)
      this.setState({ cron })
    }
  }

  componentWillUnmount() {
    const { cron } = this.state
    if (cron) clearTimeout(cron)
  }

  redir(deposit, type, redir) {
    const url = redir
      .replace('{transaction}', deposit.ID)
      .replace('{type}', type)

    window.open(url, '_self')
  }

  render() {
    const { deposit } = this.state
    return (
      <div className="Wait has-text-centered">
        <p className="title is-5 has-text-centered">
          {deposit.Status === 'completed' ? 'Redirecionando...' : 'Processando o pagamento'}
        </p>
        <br />
        <div className="box is-inline-block">
          <img src={loader} alt="Aguarde" />
        </div>
      </div>
    )
  }
}

export default Wait