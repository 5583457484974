import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MD5 } from 'crypto-js'
import moment from 'moment'
import { findTransactionsList } from '../../actions/transaction'
import noResults from '../../assets/images/no-results.svg'
import './Transaction.scss'
import Summary from '../dashboard/Summary'

const PAGINATION_ADD = 25

class Transaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cron: null,
      error: '',
      loading: false,
      filter: {
        limit: 50
      }
    }
    this.classByStatus = {
      completed: 'success',
      refused: 'danger',
      pending: 'info'
    }
    this.textByStatusAndType = {
      'deposit-completed': 'Depósito realizado',
      'deposit-pending': 'Depósito pendente',
      'deposit-refused': 'Depósito recusado',
      'user-completed': 'Transferência realizada',
      'user-pending': 'Transferência pendente',
      'user-refused': 'Transferência recusada',
      'withdrawal-completed': 'Saque realizado',
      'withdrawal-pending': 'Saque pendente',
      'withdrawal-refuse': 'Saque recusado'
    }
  }

  async componentDidMount() {
    await this.findTransactionsList()
    let cron = setInterval(async () => {
      await this.findTransactionsList()
    }, 15000)
    this.setState({ cron })
  }

  async findTransactionsList(add) {
    try {
      this.setState({ loading: true })

      const { filter } = this.state
      if (add) filter.limit += add

      await this.props.findTransactionsList(filter.limit)

      this.setState({ loading: false, filter })
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  componentWillUnmount() {
    let { cron } = this.state
    if (cron) {
      clearInterval(cron)
    }
  }

  gravatar(email) {
    const hash = MD5(email).toString()
    const url = `https://www.gravatar.com/avatar/${hash}?d=mp&s=96`
    return url
  }

  hideEmail(input) {
    let [a, b] = input.split('@')
    let c = Math.floor(a.length / 2)
    return `${a.substr(0, c)}***@${b}`
  }

  render() {
    const { loading, filter } = this.state
    const { list, user } = this.props

    return (
      <div className="Transaction">
        <Summary />
        <div className="box">
          <div className="has-scrollbar" hidden={!list.length && !loading}>
            {list.filter(t => !(t.Type === 'deposit' && t.Status === 'pending'))
              .map(t => ({ ...t, ReleasedIn: moment().diff(t.ReleasedAt, 'days') * -1 + 1 }))
              .map(t => {
                let u = user.ID === t.Receiver.ID ? t.Sender : t.Receiver
                let isDebit = t.Receiver.ID !== user.ID || t.Type === 'withdrawal'
                return (
                  <div className="columns is-mobile is-multiline is-marginless is-vcentered" key={t.ID}>
                    <div className="column is-full-mobile user">
                      <figure className="image is-48x48">
                        <img className="is-rounded" src={this.gravatar(u.Email)} alt={u.Name} />
                      </figure>
                      <div>
                        <span className="name">{u.Name}</span>
                        <p className="is-size-7">{this.hideEmail(u.Email)}</p>
                      </div>
                    </div>
                    <div className="column is-full-mobile is-one-fifth-tablet">
                      <span className={`tag is-flex has-text-centered is-${this.classByStatus[t.Status]}`}>
                        {this.textByStatusAndType[`${t.Type}-${t.Status}`]}
                      </span>
                    </div>
                    <div className="column is-half-mobile is-one-fifth-tablet has-text-centered">
                      <span className="is-size-7 is-hidden-touch">{moment(t.CreatedAt).format('LLL')}</span>
                      <span className="is-size-7 is-hidden-desktop">{moment(t.CreatedAt).fromNow()}</span>
                    </div>
                    <div className="column is-half-mobile is-one-fifth-tablet has-text-right amount">
                      <div className={`has-text-${isDebit ? 'danger' : 'success'} has-text-weight-bold`}>
                        <span hidden={(t.Type !== 'user' && t.Type !== 'deposit') || t.Status !== 'completed' || t.ReleasedIn < 1 || t.Receiver.ID !== user.ID}>
                          <i className="fas fa-lock has-text-grey" title={`O valor será liberado para saque após ${t.ReleasedIn} dias`}></i>
                          &nbsp;&nbsp;&nbsp;
                        </span>
                        <i className={`fas fa-caret-${isDebit ? 'down' : 'up'}`}></i> R$ {Number(t.Amount).toFixed(2)}
                      </div>
                    </div>
                  </div>
                )
              })}
            <div className="has-text-centered" hidden={(filter.limit > list.length || filter.limit < list.length) && !loading}>
              <br />
              <button className={`button is-primary is-rounded ${loading && 'is-loading'}`} onClick={() => this.findTransactionsList(PAGINATION_ADD)}>Ver mais</button>
              <br />
              <br />
            </div>
          </div>
          <div className="no-results" hidden={list.length || loading}>
            <figure className="image is-128x128 has-text-centered">
              <img src={noResults} alt="No results" />
            </figure>
            <p className="has-text-grey is-size-7">Nenhum resultado</p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  list: state.transaction.list,
  user: state.user
})

export default connect(mapStateToProps, { findTransactionsList })(Transaction)
