import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { stringify } from 'query-string'
import Deposit from '../deposit/Deposit'
import Navbar from './Navbar'
import './Dashboard.scss'
import Transaction from '../transaction/Transaction'
import Preloader from '../common/Preloader'
import Account from '../account/Account'
import Withdrawal from '../withdrawal/Withdrawal'
import Transfer from '../transfer/Transfer'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false
    }
  }

  componentDidMount() {
    const { user } = this.props
    if (!user || !user.ID) {
      let current = window.location.hash
      let params = stringify({
        redir: current.replace('#', '')
      })
      window.open(`#/login?${params}`, '_self')
    }
  }

  render() {
    const { user } = this.props
    const ready = user && user.ID

    return (
      <div className="Dashboard">
        <Navbar />
        {
          ready ?
            <div className="section">
              <Switch>
                <Route path="/dashboard/deposit" component={Deposit} />
                <Route path="/dashboard/transaction" component={Transaction} />
                <Route path="/dashboard/account" component={Account} />
                <Route path="/dashboard/withdrawal" component={Withdrawal} />
                {/* <Route path="/dashboard/transfer" component={Transfer} /> */}
                <Route>
                  <Redirect to="/dashboard/transaction" />
                </Route>
              </Switch>
            </div>
            : <Preloader />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(Dashboard)
