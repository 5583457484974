import Axios from 'axios'
import { setTransactionSummary } from './transaction'

export const createWithdrawal = w => {
  return async dispatch => {
    let { data: withdrawal } = await Axios.post('/withdrawals', w)
    let { data: summary } = await Axios.get('/transactions/summary')
    dispatch(setTransactionSummary(summary))
    return withdrawal
  }
}
