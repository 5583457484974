import React, { Component } from 'react'
import { connect } from 'react-redux'
import { findTransactionsBalance } from '../../actions/transaction'

const WITHDRAWAL_FEE = 5 / 100
const WITHDRAWAL_MIN = 20

class Amount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: {
        total: 0,
        withdrawal: 0,
        fee: 0
      },
      error: '',
      loading: false
    }
  }

  async componentDidMount() {
    try {
      await this.props.findTransactionsBalance()
    } catch (error) {
      this.setState({ error })
    }
  }

  change(field, type = String) {
    return event => {
      this.setState({ [field]: type(event.target.value) })
    }
  }

  amount(v) {
    let sanitized = v.replace(/[\D]+/, '')
    let total = Number(sanitized.replace(/[\D]+/, '')) / 100
    let fee = (total * WITHDRAWAL_FEE)
    let withdrawal = total - fee
    return { total, fee, withdrawal }
  }

  async confirm() {
    try {
      this.setState({ error: null, loading: true })
      const { amount: { total } } = this.state
      this.setState({ loading: false })
      window.open(`#/dashboard/withdrawal/bank?amount=${total}`, '_self')
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { amount, loading, error } = this.state
    const { balance, availableForWithdrawal, user } = this.props

    const invalidAmount = amount.total > availableForWithdrawal || amount.total < WITHDRAWAL_MIN

    return (
      <div className="Withdrawal columns is-flex is-centered has-scrollbar">
        <div className="column is-half-tablet is-two-fifths-desktop is-one-third-widescreen">
          <p className="title is-5 has-text-centered">Retirar fundos da sua Carteira Digital</p>
          <div className="notification is-danger" hidden={!error}>
            {String(error)}
          </div>
          <div className="message is-warning has-shadow">
            <div className="message-body">O saque solicitado será depositado após 10 dias</div>
          </div>
          <div className="message is-info has-shadow">
            <div className="message-body">
              As transferências feitas para você serão liberadas para saque após {user.TransactionReleasedIn} dias
              <br />
              {availableForWithdrawal > 0 ? `Atualmente o valor disponível para saque é de R$ ${Number(availableForWithdrawal).toFixed(2)}` : 'Nenhum valor disponível para saque'}
              <br />
              O valor mínimo para saque é de R$ {WITHDRAWAL_MIN.toFixed(2)}
            </div>
          </div>
          <div className="box">
            <div className="columns is-vcentered">
              <div className="column has-text-left">
                <span className="is-size-5">Digite o valor</span>
              </div>
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input className={`input is-large has-text-right ${invalidAmount ? 'is-danger' : 'is-success'}`} type="text" pattern="^((?!^0.00$).)*$" placeholder="0.00" value={amount.total ? amount.total.toFixed(2) : ''} onChange={this.change('amount', this.amount)} />
                  </div>
                  <p className={`is-size-7 has-text-right ${amount.total > availableForWithdrawal ? 'has-text-danger' : 'has-text-success'}`} hidden={amount.total < WITHDRAWAL_MIN}>
                    {availableForWithdrawal > 0 ? `Saque máximo de R$ ${Number(availableForWithdrawal).toFixed(2)}` : 'Valor indisponível para saque'}
                  </p>
                  <p className={`is-size-7 has-text-right ${amount.total < WITHDRAWAL_MIN && 'has-text-danger'}`} hidden={amount.total >= WITHDRAWAL_MIN}>
                    Saque mínimo de R$ {Number(WITHDRAWAL_MIN).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              <span className="icon has-text-primary">
                <i className="fas fa-chevron-down"></i>
              </span>
            </div>
            <div className="content">
              <table>
                <tbody>
                  <tr>
                    <td>Taxa de saque</td>
                    <td className="has-text-right">R$ {amount.fee.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Saldo restante</td>
                    <td className="has-text-right">R$ {(balance - amount.total).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Você receberá</td>
                    <td className="has-text-right">R$ {amount.withdrawal.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="has-text-centered">
            <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.confirm.bind(this)} disabled={!amount || amount.total > availableForWithdrawal || amount.total < WITHDRAWAL_MIN} type="button">Retirar fundos</button>
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  balance: state.transaction.balance,
  availableForWithdrawal: state.transaction.availableForWithdrawal
})

export default connect(mapStateToProps, { findTransactionsBalance })(Amount)