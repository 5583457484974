import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './PaymentTypes.sass'

class PaymentTypes extends Component {
  render() {
    const { pathname, search } = this.props

    const navLink = (name, path) => (
      <li className={pathname === path ? 'is-active' : ''}>
        <Link to={`${path}${search}`}>{name}</Link>
      </li>
    )

    return (
      <div className="PaymentTypes">
        <div className="tabs is-centered is-boxed is-marginless">
          <ul>
            {/* {navLink('Cartão de Crédito', '/dashboard/deposit/credit-card')} */}
            {/* {navLink('Boleto', '/dashboard/deposit/boleto')} */}
            {/* {navLink('PayPal', '/dashboard/deposit/paypal')} */}
          </ul>
        </div>
        <div className="box">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default PaymentTypes
