import { SET_TRANSACTION_SUMMARY, SET_TRANSACTION_LIST, SET_TRANSACTION_BALANCE } from '../actions/transaction'

const defaultState = {
  summary: {
    total: {
      DepositPending: 0,
      Available: 0,
      WithdrawalPending: 0,
      WithdrawalCompleted: 0
    },
    history: []
  },
  list: []
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_TRANSACTION_SUMMARY:
      return { ...state, summary: action.summary }
    case SET_TRANSACTION_LIST:
      return { ...state, list: action.list }
    case SET_TRANSACTION_BALANCE:
      return { ...state, balance: action.balance, availableForWithdrawal: action.availableForWithdrawal }
    default:
      return state
  }
}