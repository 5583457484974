import Axios from 'axios'

export const SET_BANK_LIST = 'SET_BANK_LIST'

export const listBank = () => {
  return async dispatch => {
    let { data: list } = await Axios.get('/banks')
    dispatch(setBankList(list))
    return list
  }
}

export const setBankList = list => {
  return dispatch => {
    dispatch({ type: SET_BANK_LIST, list })
  }
}
