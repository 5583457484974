import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { MD5 } from 'crypto-js'
import { clearUser } from '../../actions/user'
import './NavbarAccount.scss'

class NavbarAccount extends Component {
  logout() {
    this.props.clearUser()
    window.open('#/login', '_self')
  }

  gravatar(email) {
    const hash = MD5(email).toString()
    const url = `https://www.gravatar.com/avatar/${hash}?d=mp&s=96`
    return url
  }

  open() {
    const { isMobile } = this.props
    if (isMobile) window.open('#/dashboard/account', '_self')
  }

  render() {
    const { user, isMobile } = this.props

    return (
      <div className={`NavbarAccount ${isMobile && 'is-mobile'}`} onClick={this.open.bind(this)}>
        <figure className="image is-48x48">
          <img className="is-rounded" src={this.gravatar(user.Email)} alt={user.Name} />
        </figure>
        <div className="name is-hidden-mobile">
          <span>{user.Name}</span>
          <Link className="is-size-7" to="/dashboard/account">Minha conta</Link>
        </div>
        <span className="icon button is-rounded is-paddingless is-inverted is-primary" onClick={this.logout.bind(this)}>
          <i className="fas fa-power-off"></i>
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, { clearUser })(NavbarAccount)
