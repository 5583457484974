import React, { Component } from 'react'
import logo from '../../assets/images/logo@2x.webp'
import './Preloader.scss'

class Preloader extends Component {
  render() {
    const { hidden } = this.props

    return (
      <div className="Preloader has-background-light" hidden={hidden}>
        <figure className="image is-64x64 is-inline-block">
          <img src={logo} alt="Retomando" />
        </figure>
        <br />
      </div>
    )
  }
}

export default Preloader