import React, { Component } from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import env from '../../env'
import './Amount.sass'

const DEPOSIT_MAX = 240

class Amount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: '',
      min: 0,
      accept: false,
      refund: false,
      loading: false,
      error: ''
    }
  }

  componentDidMount() {
    let { min, redir } = parse(this.props.location.search)
    if (min) {
      min = Number(min)
      this.setState({ min, amount: min.toFixed(2) })
    }
    if (!redir) {
      redir = '#/dashboard/transactions'
    }
    this.setState({ redir })
  }

  change(field) {
    return event => {
      let sanitized = event.target.value.replace(/[\D]+/, '')
      let amount = Number(sanitized.replace(/[\D]+/, '')) / 100
      this.setState({ amount: amount.toFixed(2) })
    }
  }

  async confirm() {
    try {
      this.setState({ error: null, loading: true })
      const { amount, redir } = this.state
      await this.setState({ loading: false })
      window.open(`#/dashboard/deposit/paypal?amount=${amount}&redir=${encodeURIComponent(redir)}`, '_self')
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  alert() {
    this.setState({ refund: true })
  }

  cancel() {
    this.setState({ refund: false })
  }

  accept() {
    let { accept } = this.state
    this.setState({ accept: !accept })
  }

  render() {
    const { amount, min, loading, refund, accept, error } = this.state

    return (
      <div className={`Amount has-text-centered ${this.props.className}`}>
        <p className="title is-5 has-text-centered">Enviar fundos para sua Carteira Digital</p>
        <div className="notification is-danger" hidden={!error}>
          {String(error)}
        </div>
        <div className="box">
          <div className="columns is-vcentered">
            <div className="column has-text-left">
              <span className="is-size-5">Digite o valor</span>
            </div>
            <div className="column">
              <div className="field">
                <div className="control">
                  <input className={`input is-large has-text-right ${(amount < min || amount > DEPOSIT_MAX) ? 'is-danger' : 'is-success'}`} type="text" pattern="^((?!^0.00$).)*$" placeholder="0.00" value={amount} onChange={this.change('amount')} />
                </div>
                <p className={`is-size-7 has-text-right ${amount < min ? 'has-text-danger' : 'has-text-success'}`} hidden={!min}>
                  Deposito mínimo de R$ {Number(min).toFixed(2)}
                </p>
                <p className={`is-size-7 has-text-right ${amount > DEPOSIT_MAX ? 'has-text-danger' : 'has-text-success'}`} hidden={amount <= DEPOSIT_MAX}>
                  Deposito máximo de R$ {Number(DEPOSIT_MAX).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="has-text-centered">
          <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.alert.bind(this)} disabled={amount === '0.00' || !amount || amount < min || amount > DEPOSIT_MAX} type="button">Continuar</button>
        </p>
        <div className={`modal modal-refund ${refund && 'is-active'}`}>
          <div className="modal-background" onClick={this.cancel.bind(this)}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Atenção!</p>
              <button className="delete" aria-label="close" onClick={this.cancel.bind(this)}></button>
            </header>
            <section className="modal-card-body">
              Esse pagamento não terá reembolso. <br />
              Concorda e tem certeza que deseja continuar? <br /><br />
              <label class="checkbox"><input type="checkbox" value={accept} onChange={this.accept.bind(this)} /> Concordo e desejo continuar</label>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-primary" onClick={this.confirm.bind(this)} disabled={!accept}>Continuar</button>
              <button className="button" onClick={this.cancel.bind(this)}>Cancelar</button>
            </footer>
          </div>
        </div>
      </ div>
    )
  }
}

export default connect(null, null)(Amount)
