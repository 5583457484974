import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import Axios from 'axios'
import { HashRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import { stringify } from 'query-string'
import moment from 'moment'
import 'moment/locale/pt-br'
import env from './env'

Axios.defaults.baseURL = env.api

Axios.interceptors.response.use(res => res, err => {
  if (err.response && err.response.status === 401) {
    localStorage.removeItem('token')
    let current = window.location.hash
    if (current.indexOf('#/login') < 0) {
      let params = stringify({
        redir: current.replace('#', '')
      })
      window.open(`#/login?${params}`, '_self')
    }
  }
  return Promise.reject(err.response && err.response.data ? err.response.data.error : err)
})

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

moment.locale(navigator.language)

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
