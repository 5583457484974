import React, { Component } from 'react'
import { parse } from 'query-string'
import { connect } from 'react-redux'
import { listBank } from '../../actions/bank'
import { lastWithdrawalOption } from '../../actions/withdrawal-option'
import { createWithdrawal } from '../../actions/withdrawal'
import { findTransactionsBalance } from '../../actions/transaction'
import Axios from 'axios'

const WITHDRAWAL_FEE = 5 / 100
const WITHDRAWAL_MIN = 20

const ACCOUNT_TYPES = [
  'Conta corrente',
  'Conta poupança',
  'Conta salário'
]

class Bank extends Component {
  constructor(props) {
    super(props)
    this.state = {
      banks: [],
      amount: {
        total: 0,
        withdrawal: 0,
        fee: 0
      },
      form: {
        Name: '',
        CPF: '',
        BankID: '',
        Agency: '',
        AgencyDigit: '',
        AccountType: '',
        Account: '',
        AccountDigit: '',
        isValid: false
      },
      auth: {
        PrivateKey: '',
        PublicKey: ''
      },
      error: '',
      loading: false
    }
  }

  async componentDidMount() {
    let amount = this.parseAmount()
    if (!amount.total) {
      window.open('#/dashboard/withdrawal/amount', '_self')
      return
    }

    let balance = await this.loadBalance()
    if (!balance || balance < amount.total) {
      window.open('#/dashboard/withdrawal/amount', '_self')
      return
    }

    let { form } = this.state
    if (!form.AccountType) form.AccountType = ACCOUNT_TYPES[0]

    let banks = await this.loadBanks()
    if (!form.BankID) form.BankID = banks[0].ID

    let option = await this.loadLastOption()
    if (option) form = { ...option, isValid: true }

    delete form['Bank']

    this.setState({ form, amount })
  }

  parseAmount() {
    let { amount: total } = parse(this.props.location.search)
    total = Number(total || 0)
    let fee = (total * WITHDRAWAL_FEE)
    let withdrawal = total - fee
    return { total, fee, withdrawal }
  }

  async loadLastOption() {
    try {
      return await this.props.lastWithdrawalOption()
    } catch (error) {
      console.warn('lastWithdrawalOption', error)
    }
  }

  async loadBanks() {
    try {
      return await this.props.listBank()
    } catch (error) {
      this.setState({ error })
    }
  }

  async loadBalance() {
    try {
      return await this.props.findTransactionsBalance()
    } catch (error) {
      this.setState({ error })
    }
  }

  change(field, type = String) {
    return event => {
      let { form } = this.state
      form[field] = type(event.target.value)
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  cpf(input) {
    let sanitized = input.replace(/\D/g, '')

    let formated = sanitized.substring(0, 3)
    if (sanitized.length > 3) formated += `.${sanitized.substring(3, 6)}`
    if (sanitized.length > 6) formated += `.${sanitized.substring(6, 9)}`
    if (sanitized.length > 9) formated += `-${sanitized.substring(9, 11)}`

    return formated
  }

  changePrivateKey(event) {
    const { auth } = this.state
    auth.PrivateKey = event.target.value
    this.setState({ auth })
  }

  number(input) {
    return input.replace(/\D/g, '')
  }

  async verify() {
    try {
      this.setState({ error: null, loading: true })
      const { amount, form } = this.state
      const { data: auth } = await Axios.post('/withdrawals/verify', { Option: form, Amount: amount.total })
      this.setState({ auth, loading: false })
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  async confirm() {
    try {
      this.setState({ error: null, loading: true })
      const { amount, form, auth } = this.state
      await this.props.createWithdrawal({ Withdrawal: { Option: form, Amount: amount.total }, ...auth })
      this.setState({ loading: false })
      window.open('#/dashboard/transaction', '_self')
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { amount, form, auth, loading, error } = this.state
    const { banks, balance, availableForWithdrawal, user } = this.props

    return (
      <div className="Withdrawal is-flex columns is-centered has-scrollbar">
        <div className="column is-two-thirds-tablet is-half-desktop is-two-fifths-widescreen is-one-third-fullhd">
          <p className="title is-5 has-text-centered">Retirar fundos da sua Carteira Digital</p>
          <div className="notification is-danger" hidden={!error}>
            {String(error)}
          </div>
          <div className="message is-warning has-shadow">
            <div className="message-body">O saque solicitado será depositado após 10 dias</div>
          </div>
          <div className="message is-info has-shadow">
            <div className="message-body">
              As transferências feitas para você serão liberadas para saque após {user.TransactionReleasedIn} dias
              <br />
              {availableForWithdrawal > 0 ? `Atualmente o valor disponível para saque é de R$ ${Number(availableForWithdrawal).toFixed(2)}` : 'Nenhum valor disponível para saque'}
              <br />
              O valor mínimo para saque é de R$ {WITHDRAWAL_MIN.toFixed(2)}
            </div>
          </div>
          <div className="box">
            <form className="columns is-multiline is-mobile" ref="form" hidden={auth.PublicKey}>
              <div className="column is-full-mobile is-half-tablet">
                <div className="field">
                  <label className="label">Nome do titular</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="Digite o nome do titular" value={form.Name} onChange={this.change('Name')} required />
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half-tablet">
                <div className="field">
                  <label className="label">CPF</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="Digite o CPF do titular" value={form.CPF} onChange={this.change('CPF', this.cpf)} required />
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half-tablet">
                <div className="field">
                  <label className="label">Banco</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select value={form.BankID} onChange={this.change('BankID', Number)} required>
                        {banks.map((b, i) => (
                          <option value={b.ID} key={i}>{b.Name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-two-thirds-mobile is-one-third-tablet">
                <div className="field">
                  <label className="label">Agência</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="Digite o número da agência" value={form.Agency} onChange={this.change('Agency', this.number)} required />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Dígito</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="" value={form.AgencyDigit} onChange={this.change('AgencyDigit', this.number)} />
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half-tablet">
                <div className="field">
                  <label className="label">Tipo da conta</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select value={form.AccountType} onChange={this.change('AccountType')} required>
                        {ACCOUNT_TYPES.map((t, i) => (
                          <option value={t} key={i}>{t}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-two-thirds-mobile is-one-third-tablet">
                <div className="field">
                  <label className="label">Número da conta</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="Digite o número da conta" value={form.Account} onChange={this.change('Account', this.number)} required />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Dígito</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="" value={form.AccountDigit} onChange={this.change('AccountDigit', this.number)} />
                  </div>
                </div>
              </div>
            </form>
            <form className="columns is-vcentered is-multiline is-marginless" ref="form" noValidate hidden={!auth.PublicKey}>
              <div className="column">Enviamos um código para o seu email, digite ele na caixa ao lado</div>
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input className="input has-text-centered is-large" type="text" placeholder="Digite aqui" value={form.code} onChange={this.changePrivateKey.bind(this)} required={auth.PublicKey} />
                  </div>
                </div>
              </div>
            </form>
            <div className="content">
              <table>
                <tbody>
                  <tr>
                    <td>Taxa de saque</td>
                    <td className="has-text-right">R$ {amount.fee.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Saldo restante</td>
                    <td className="has-text-right">R$ {(balance - amount.total).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Você receberá</td>
                    <td className="has-text-right">R$ {amount.withdrawal.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="has-text-centered">
            <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.verify.bind(this)} disabled={!form.isValid} type="button" hidden={auth.PublicKey}>Retirar fundos</button>
            <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.confirm.bind(this)} disabled={!form.isValid} type="button" hidden={!auth.PublicKey}>Confirmar</button>
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  banks: state.bank.list,
  lastOption: state.withdrawalOption.last,
  balance: state.transaction.balance,
  availableForWithdrawal: state.transaction.availableForWithdrawal
})

export default connect(mapStateToProps, {
  listBank,
  lastWithdrawalOption,
  createWithdrawal,
  findTransactionsBalance
})(Bank)