import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearUser } from '../../actions/user'
import NavbarAccount from './NavbarAccount'
import logo from '../../assets/images/logo@2x.webp'
import logoSt from '../../assets/images/logo-st@2x.webp'
import logoStSmall from '../../assets/images/icon-st@2x.webp'
import env from '../../env'
import './Navbar.scss'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false
    }
  }

  logout() {
    this.props.clearUser()
    window.open('#/login', '_self')
  }

  toggle(isActive) {
    return () => this.setState({ isActive })
  }

  render() {
    const { isActive } = this.state

    return (
      <div className="Navbar">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <span className="navbar-item is-unselectable">
              <img src={logo} alt="Carteira Digital" />
              &nbsp;&nbsp;<span className="title is-size-6">Carteira Digital</span>
            </span>

            <div className="navbar-account-mobile is-hidden-desktop" onClick={this.toggle(false)}>
              <a href={env.sociotasking} title="Clique aqui para voltar ao Socio Tasking">
                <figure className="image sociotasking is-hidden-mobile">
                  <img src={logoSt} alt="SocioTasking" />
                </figure>
                <figure className="image sociotasking is-hidden-tablet">
                  <img src={logoStSmall} alt="SocioTasking" />
                </figure>
              </a>
              <NavbarAccount isMobile />
            </div>

            <span role="button" className={`navbar-burger burger is-marginless ${isActive && 'is-active'}`} aria-label="menu" aria-expanded="false" onClick={this.toggle(!isActive)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>

          <div className={`navbar-menu ${isActive && 'is-active'}`} onClick={this.toggle(false)}>
            <div className="navbar-start">
              <NavLink className="navbar-item is-hidden-desktop" activeClassName="has-background-light" to="/dashboard/account">Minha conta</NavLink>
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/dashboard/transaction">Transações</NavLink>
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/dashboard/deposit">Depositar</NavLink>
              {/* <NavLink className="navbar-item" activeClassName="has-background-light" to="/dashboard/transfer">Transferir</NavLink> */}
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/dashboard/withdrawal">Sacar</NavLink>
              <span className="navbar-item is-hidden-tablet" onClick={this.logout.bind(this)}>Sair</span>
            </div>

            <div className="navbar-end is-hidden-touch">
              <a href={env.sociotasking} title="Clique aqui para voltar ao Socio Tasking">
                <figure className="image sociotasking is-hidden-mobile">
                  <img src={logoSt} alt="SocioTasking" />
                </figure>
                <figure className="image sociotasking is-hidden-tablet">
                  <img src={logoStSmall} alt="SocioTasking" />
                </figure>
              </a>
              <NavbarAccount />
            </div>
          </div>
        </nav>
      </div >
    )
  }
}

export default connect(null, { clearUser })(Navbar)
