import React, { Component } from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { createDeposit } from '../../actions/deposit'
import env from '../../env'
import PaymentTypes from './PaymentTypes'

const PAYPAL_FEE = 4.79 / 100
const PAYPAL_TAX = 0.6

class PayPal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: {
        deposit: 0,
        total: 0,
        tax: 0
      },
      loading: false,
      error: ''
    }
  }

  componentDidMount() {
    let { amount: deposit, redir } = parse(this.props.location.search)
    deposit = Number(deposit)
    if (!deposit) {
      window.open('#/dashboard/deposit/amount', '_self')
      return
    }
    if (!redir) {
      redir = '#/dashboard/transactions'
    }
    let tax = deposit * PAYPAL_FEE + PAYPAL_TAX
    let total = deposit + tax
    this.setState({ amount: { deposit, tax, total }, redir })
  }

  async confirm() {
    try {
      this.setState({ error: null, loading: true })

      const { createDeposit } = this.props
      const { amount, redir } = this.state
      const { form } = this.refs

      const deposit = await createDeposit({ amount: amount.deposit })

      let params = `transaction=${deposit.ID}&redir=${redir}`
      let { origin, pathname } = window.location

      form.custom.value = deposit.ID
      form.return.value = `${origin}${pathname}#/dashboard/deposit/wait?${params}`
      form.submit()
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { location } = this.props
    const { amount } = this.state
    const { loading, error } = this.state

    return (
      <div className="PayPal columns is-centered is-flex has-scrollbar">
        <div className="column is-half-tablet is-two-fifths-widescreen is-one-third-fullhd">
          <p className="title is-5 has-text-centered">Enviar fundos para sua Carteira Digital</p>
          <div className="notification is-danger" hidden={!error}>
            {String(error)}
          </div>
          <PaymentTypes pathname={location.pathname} search={location.search}>
            <div className="columns is-marginless">
              <div className="column">
                <p>O depósito com PayPal é rápido e fácil, sem complicações</p>
              </div>
              <div className="column is-one-third">
                <div className="columns is-marginless is-gapless is-mobile is-multiline is-vcentered">
                  <div className="column is-full-tablet">
                    <div className="has-text-right-tablet">Você está enviando</div>
                  </div>
                  <div className="column is-full-tablet">
                    <div className="is-size-5 has-text-success has-text-right">R$ {amount.deposit.toFixed(2)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-size-7 is-marginless">
              <div className="column">
                Taxa adicional de R$ {amount.tax.toFixed(2)}
              </div>
              <div className="column has-text-right-tablet">
                Valor total a ser pago R$ {amount.total.toFixed(2)}
              </div>
            </div>
            <form action={env.paypal.action} method="post" ref="form">
              <input type="hidden" name="cmd" value="_xclick" />
              <input type="hidden" name="business" id="business" value={env.paypal.business} />
              <input type="hidden" name="item_name" value="contract-engagement-basic-lot" />
              <input type="hidden" name="item_number" id="item_number" value="" />
              <input type="hidden" name="amount" value={amount.deposit.toFixed(2)} />
              <input type="hidden" name="tax" value={amount.tax.toFixed(2)} />
              <input type="hidden" name="return" value="" />
              <input type="hidden" name="quantity" value="1" />
              <input type="hidden" name="currency_code" value="BRL" />
              <input type="hidden" name="custom" id="custom" value="" />
              <input type="hidden" name="notify_url" value={env.paypal.notify} />
            </form>
          </PaymentTypes>
          <p className="has-text-centered">
            <button className={`button is-medium is-primary ${loading && 'is-loading'}`} onClick={this.confirm.bind(this)} type="button">Confirmar</button>
          </p>
        </div>
      </div>
    )
  }
}

export default connect(null, { createDeposit })(PayPal)