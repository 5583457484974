import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Amount from './Amount'
import CreditCard from './CreditCard'
import PayPal from './PayPal'
import Wait from './Wait'
import Boleto from './Boleto'

class Deposit extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="Deposit is-flex columns is-vcentered is-centered">
        <Switch>
          <Route path="/dashboard/deposit/amount" component={Amount} />
          {/* <Route path="/dashboard/deposit/credit-card" component={CreditCard} /> */}
          <Route path="/dashboard/deposit/paypal" component={PayPal} />
          {/* <Route path="/dashboard/deposit/boleto" component={Boleto} /> */}
          <Route path="/dashboard/deposit/wait" component={Wait} />
          <Route path="*">
            <Redirect to="/dashboard/deposit/amount" />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default Deposit
