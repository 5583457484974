import Axios from 'axios'

export const SET_USER = 'SET_USER'

export const setUser = user => {
  return { type: SET_USER, user }
}

export const createUser = u => {
  return async dispatch => {
    let { data: { user, token } } = await Axios.post('/users', u)
    Axios.defaults.headers.Authorization = `Bearer ${token}`
    window.localStorage.setItem('Authorization', token)
    dispatch(setUser(user))
  }
}

export const recoveryUser = () => {
  return async dispatch => {
    Axios.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem('Authorization')}`
    let { data: user } = await Axios.get('/users')
    dispatch(setUser(user))
  }
}

export const loginUser = (u) => {
  return async dispatch => {
    let { data: { user, token } } = await Axios.post('/users/login', u)
    Axios.defaults.headers.Authorization = `Bearer ${token}`
    window.localStorage.setItem('Authorization', token)
    dispatch(setUser(user))
  }
}

export const loginByTokenUser = t => {
  return async dispatch => {
    Axios.defaults.headers.Authorization = `Bearer ${t}`
    let { data: user } = await Axios.get('/users')
    window.localStorage.setItem('Authorization', t)
    dispatch(setUser(user))
  }
}

export const clearUser = () => {
  return dispatch => {
    Axios.defaults.headers.Authorization = ''
    window.localStorage.removeItem('Authorization')
    dispatch(setUser({}))
  }
}