import React, { Component } from 'react'
import { connect } from 'react-redux'
import { findTransactionsSummary } from '../../actions/transaction'
import SummaryChart from './SummaryChart';

class Summary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cron: null
    }
  }

  async componentDidMount() {
    if (this.props.user.ID) {
      await this.props.findTransactionsSummary()
    }
    let cron = setInterval(async () => {
      if (this.props.user.ID) {
        await this.props.findTransactionsSummary()
      }
    }, 15000)
    this.setState({ cron })
  }

  componentWillUnmount() {
    let { cron } = this.state
    if (cron) {
      clearInterval(cron)
    }
  }

  render() {
    const { summary: { total, history } } = this.props
    const { cron } = this.state
    const totals = [
      // { type: 'info', amount: total.DepositPending, title: 'Depósitos pendentes' },
      { type: 'success', amount: total.Available, title: 'Saldo disponível' },
      { type: 'warning', amount: total.WithdrawalPending, title: 'Saques solicitados' },
      { type: 'default', amount: total.WithdrawalCompleted, title: 'Saques pagos' }
    ]

    return (
      <div className="Summary">
        <div className="message has-shadow is-success is-marginless is-hidden-tablet">
          <div className="message-body">
            <div className="columns is-vcentered is-mobile">
              <div className="column">
                <p className="is-size-7">{totals[0].title}</p>
              </div>
              <div className="column has-text-right">
                <p className="is-size-6">R$ {Number(totals[0].amount).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-multiline is-hidden-mobile">
          <div className="column">
            <div className="columns is-multiline">
              {totals.map((e, i) => (
                <div className="column is-one-third-tablet" key={i}>
                  <div className={`message has-shadow is-${e.type}`}>
                    <div className="message-body">
                      <div className="columns is-multiline is-vcentered is-gapless">
                        <div className="column is-full-tablet is-half-desktop">
                          <p className="is-size-6">{e.title}</p>
                        </div>
                        <div className="column is-full-tablet is-half-desktop has-text-right">
                          <p className="is-size-5">R$ {Number(e.amount).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="column is-half-desktop is-three-fifths-fullhd is-hidden-tablet-only">
            <SummaryChart data={history} hidden={!cron} />
          </div> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  summary: state.transaction.summary
})

export default connect(mapStateToProps, { findTransactionsSummary })(Summary)
