import React, { Component } from 'react'
import { AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts'
import './SummaryChart.scss'

class SummaryChart extends Component {
  render() {
    const { data, hidden } = this.props
    const history = (new Array(30)).fill(null).map(() => ({ Amount: -1 }))

    for (let i = 0; i < data.length; i++) {
      const e = data[i]
      history[30 - e.Day] = { Amount: e.Amount }
    }

    for (let i = 0; i < history.length - 1; i++) {
      const a = history[i + 1] || { Amount: -1 }
      const b = history[i] || { Amount: -1 }
      if (b.Amount === -1) {
        b.Amount = 0
      }
      if (a.Amount === -1) {
        a.Amount = b.Amount
      }
    }

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        return (
          <div className="tag has-shadow">
            {label === 30 ? 'Hoje' : `Há ${30 - label} dias`}
            <br />
            R$ {Number(payload[0].value).toFixed(2)}
          </div>
        );
      }

      return null;
    };

    return (
      <div className="SummaryChart message is-success has-shadow">
        <div className="message-body">
          <ResponsiveContainer>
            <AreaChart data={history}>
              {!hidden && <Area type="monotone" dataKey="Amount" stroke="#23d160" strokeWidth={2} fill="#23d160" fillOpacity={0.1} dot={false} />}
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default SummaryChart
