import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { recoveryUser, loginUser, loginByTokenUser } from '../../actions/user'
import Preloader from '../common/Preloader'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        Email: '',
        Password: '',
        isValid: true
      },
      loading: false,
      ready: false,
      error: ''
    }
  }

  async componentDidMount() {
    let { redir, token } = parse(this.props.location.search)
    if(token) {
      await this.loginByToken(token, redir)
    } else {
      await this.recovery(redir)
    }
    this.setState({ redir, ready: true })
  }

  change(field) {
    return event => {
      let { form } = this.state
      form[field] = event.target.value
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  async login() {
    try {
      this.setState({ error: null, loading: true })
      const { form, redir } = this.state
      await this.props.loginUser(form)
      this.setState({ loading: false })
      window.open(`#${redir || '/dashboard/transaction'}`, '_self')
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  async recovery(redir='/dashboard/transaction') {
    try {
      this.setState({ loading: true })
      await this.props.recoveryUser()
      window.open(`#${redir}`, '_self')
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  async loginByToken(token, redir='/dashboard/transaction') {
    try {
      this.setState({ loading: true })
      await this.props.loginByTokenUser(token)
      window.open(`#${redir}`, '_self')
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { form, loading, ready, error } = this.state

    return (
      <div className="Login">
        <div className="columns is-centered">
          <div className="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
            <div className="notification is-danger" hidden={!error}>
              {String(error)}
            </div>
            <Preloader hidden={ready} />
            <form className="box" ref="form" hidden={!ready}>
              <div className="field">
                <label className="label">E-mail</label>
                <div className="control">
                  <input className="input" type="email" placeholder="Digite seu e-mail" autoComplete="username" value={form.Email} onChange={this.change('Email')} required />
                </div>
              </div>
              <div className="field">
                <label className="label">Senha</label>
                <div className="control">
                  <input className="input" type="password" placeholder="Digite sua senha" autoComplete="current-password" value={form.Password} onChange={this.change('Password')} required />
                </div>
              </div>
              <br />
              <div className="columns">
                <div className="column">
                  <Link className="button is-fullwidth" to="/register">Criar conta</Link>
                </div>
                <div className="column">
                  <button className={`button is-fullwidth is-primary ${loading && 'is-loading'}`} onClick={this.login.bind(this)} disabled={!form.isValid} type="button">Entrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, { recoveryUser, loginUser, loginByTokenUser })(Login)
