import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import './App.scss'
import Auth from './auth/Auth'

class App extends Component {
  render() {
    return (
      <div className="App has-background-light">
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/*" component={Auth} />
          <Route>
            <h1>404</h1>
          </Route>
        </Switch>
      </div>
    )
  }
}

export default App
