import Axios from 'axios'
import { setTransactionSummary } from './transaction'

export const SET_DEPOSIT_AMOUNT = 'SET_DEPOSIT_AMOUNT'

export const createDeposit = d => {
  return async dispatch => {
    const {data: deposit } = await Axios.post('/deposits', d)
    let { data: summary } = await Axios.get('/transactions/summary')
    dispatch(setTransactionSummary(summary))
    return deposit
  }
}

export const setDepositAmount = amount => {
  return dispatch => {
    dispatch({ type: SET_DEPOSIT_AMOUNT, amount })
  }
}
