import { combineReducers } from 'redux'

import user from './reducers/user'
import transaction from './reducers/transaction'
import deposit from './reducers/deposit'
import bank from './reducers/bank'
import withdrawalOption from './reducers/withdrawal-option'
import withdrawal from './reducers/withdrawal'

export default combineReducers({
  user,
  transaction,
  deposit,
  bank,
  withdrawalOption,
  withdrawal
})